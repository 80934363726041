import React from "react"
// import * as style from "./style.module.scss"

const Explore = () => {
  return (
    <div className="ray__block">
      [explore]
    </div>
  )
}

export default Explore
