import React from "react"
import { Helmet } from "react-helmet"
import MainLayout from "@/layouts/Main"
import Explore from "@/components/Explore"

const Page = () => {
  return (
    <MainLayout>
      <Helmet title="Explore Projects" />
      <Explore />
    </MainLayout>
  )
}

export default Page
